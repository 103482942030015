import React,{useContext, useState, useEffect} from 'react';
import { BsFillCaretDownFill } from "react-icons/bs";
import { BiChevronDown } from "react-icons/bi";
import { MdPassword,MdCurrencyRupee } from "react-icons/md";
import { FaClipboardList,FaHistory } from "react-icons/fa";
import { GrSecure, GrLogout } from "react-icons/gr";

import { useNavigate } from 'react-router-dom';
import Context from '../../../Context/Context';
import { userFuncion } from '../../../Helper/helper';
import styles from "./Navbar.module.scss";
import jwt_decode from "jwt-decode";
const DropDownMenu = ({valways}) => {
  const getUser = sessionStorage.getItem("user");
 
  const { states, changeState } = useContext(Context);
    const [isDrop, setIsDrop] = useState(false);
    const [username, setUsername] = useState("User");

    useEffect(() => {
      if (getUser !== "" && getUser !== "false") {
        try {
          const DecodedUser = jwt_decode(getUser);
          if (DecodedUser) {
            //console.log("DecodedUser=>",DecodedUser);
            const finalSentence = DecodedUser?.name.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
            setUsername(finalSentence)
          }
          } catch (e) {
          }
        }
    
      },[])
    const navigate=useNavigate()
    
  return (
    <>
        <div style={{display : valways && 'flex' , marginLeft : valways && 0}} className={`${styles.menud} dropMenu`}>

            <span
              className='mrMain'
              to="#"
              onClick={()=>setIsDrop(true)}
             >
              <span className="mr">Hi, {username} <BiChevronDown /></span>
              <img src="/user.jpg" className="img-fluid mrImg" />

            </span>
                {isDrop && <div style={{left : valways && 0 }} className={`${styles.box} dropMenuIn flex-center column`}>
<span
              className='mrMain'
              to="#"
              onClick={()=>setIsDrop(true)}
             >
              <img src="/user.jpg" className="img-fluid mrImg" /> 
              <span className="mr">Hi, {username} </span>

            </span>
                {/* <a onClick={()=>setIsDrop(false)} href="/personal-information"> Personal Information</a> */}
                <a onClick={()=>setIsDrop(false)} href="/pension-plan"><i><MdCurrencyRupee /></i> Pension Plan</a>
                <a onClick={()=>setIsDrop(false)} href="/change-password"><i><MdPassword /></i> Change Password</a>
                <a onClick={()=>setIsDrop(false)} href="/bank-details"><i><FaClipboardList /></i> Bank Details</a>
                <a onClick={()=>setIsDrop(false)} href="/history"><i><FaHistory /></i> History</a>
                <a onClick={()=>setIsDrop(false)} href="/security"><i><GrSecure /></i> Security</a>
                <button style={{color: "#fff"}} onClick={()=>{setIsDrop(false)
                userFuncion(false)
                changeState(states.dmode, window.innerWidth, states.stokenbtn , states.buyTrade , false)
                navigate("/exchange")
                }} ><i><GrLogout /></i> Logout</button>
                </div>}
              </div>
              {isDrop && <div onClick={()=>setIsDrop(false)} className={styles._boxbg}></div>}
    </>
  )
}

export default DropDownMenu